import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/CaseStudyLayout/CaseStudyLayout.tsx";
import Helmet from 'react-helmet';
import CaseStudyIntro from '../components/CaseStudyIntro/CaseStudyIntro';
import CaseStudyConclusion from '../components/CaseStudyConclusion/CaseStudyConclusion';
import NextCaseStudy from '../components/NextCaseStudy/NextCaseStudy';
import AutoplayVideo from '../components/AutoplayVideo/AutoplayVideo';
import Wrapper from '../components/Wrapper/Wrapper';
import Container from '../components/Container/Container';
import heroImage from '../images/GeoSure-Hero@2x.png';
import dayNightVid from './geosure/GeoSure-DayNight.mp4';
import shareImage from '../images/Cinebody-Thumb@2x.png';
import digColorsThumb from '../images/DIG-Colors-Thumb@2x.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Helmet meta={[{
      property: 'og:image',
      content: `https://tinyeahno.com${shareImage}`
    }, {
      name: 'twitter:image',
      content: `https://tinyeahno.com${shareImage}`
    }]} mdxType="Helmet" />
    <CaseStudyIntro title="GeoSure" subtitle="Native apps designed to empower world travelers with safety and security data." heroKey="geosure" content={[{
      kind: 'text',
      headline: 'The Brief',
      text: "GeoSure is a mobile app that empowers individuals to travel with confidence by providing objective, personalized, real-time information about neighborhoods, cities, and countries the world over. Our relationship began with a re-design of the old GeoSure app in 2017 and continued the following year with the addition of “premium” features for enterprise. The initial redesign scrapped all the previous app’s styling and established a fresh perspective on GeoSure’s digital presence."
    }]} aside={[{
      headline: 'My Role',
      items: ['Design Strategy', 'Digital Product Design', 'Visual Design']
    }, {
      headline: 'Download the App',
      items: ['[Apple App Store](https://itunes.apple.com/us/app/geosure/id811335043)', '[Google Play](https://play.google.com/store/apps/details?id=com.geosure&hl=en)']
    }]} darkBg mdxType="CaseStudyIntro" />
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAADXUlEQVQ4y12TT2hcVRTGZ+VawZW4UNtVG7Ftapu0AWMqJdqkxtZi0i5KsVUIBYNxYf4ZSxLQUphqKUoVulAU40oUREgRLFJBpU0lIbQ1mZk3k8SZ5s289+57976Xmfl57qRx4eJwHudxvnO+73w3peOYULnosEw1UY1IYolajZnvfuLTiYt8deFjfrj0Ed9fmOLXLz7n8pUvGTjzJt9eTjP89gDtB1+h92Q/lUCRikyMF3j4EpEO0bEmNBFV4LP0Jfpe6OSNl4/S3/USpzs7effMWc6/P0r/wTam+noYOd7Dic7dnO1rI1C+AMYJ9wMfN/QJqwlBYvAFtCaAg8Pn2LKtmaYde3jk0cd46OHH2dtxiKvpcSa6mkmfaGOiez9vHWvh9KGnWXNdUtoYdKWC9oVmqEmUxqioATj1wXl6jrzGq8eOs/+5Dlpb93Hq1Ek+nDxH74vtvN57mCPtLXTs3Ulv1/O45TIpYzSRmyPw1khMQKJ9TOSzXqvzzfQ0Q0NDTE5OMj7+HmOjI6TTafLLKyxmsuRyDrn8MplcjtXVZax8KS2UtWgWCuUk0Y0wtYS6pTw4yFNPPsHOHc/Q1LSdrVu3cOBAB/lCnkLBwXFyOHmHJSdPTiLSZuMogVckCkpyXQH1S7ifXKQm9eGxUZq2b2Nfa6vQbaG5eRfdXV0NEMdxyOaykmXTfI5F2Ta0gNY2vvIoBxW5riIOPYLrP1NbrzIyNtbYzIK1tuzh2d27ONzdTdbJMJf9U2gvsZTJkMkuUVgubAD6Ouad3+5z9NoqVxcq1KtVPKGrkypONsPsrVnmb89x668Fbt6eZ+7eXTyl8KIicaQwoRLLBbKUEulEw3IUM3CjSM/MClfmXUrK8PWdSiP/6Bj+EAdMe3fBvwbl68QzN6kKyLyb8EtB8Xsx4h8VEycJ1oINH86VQm44LjnP4MqA2VKE3fyeq3H8gHm/KLZaJPLFDYvL4gRNITD8XY7+67G3iEzy4MrJOkqFGNHNarou04zUEx3JsayVEjxTF1qGuF5j1QtZLHnk1zzxXoWVtQoL+SK++DhlUVUsgJJDAdmYFGMHWZFVKJYS89taGEUY+1Sl0RXzl4JInq1CRZqSPIxQWx/a5gcAm9kO2QT9f9h/dnsbVrfN+ub3v6Ewq2TjogY7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/96fbe88ba783f088fd2b87b21a9107ef/e93cc/GeoSure-Style%402x.webp 300w", "/static/96fbe88ba783f088fd2b87b21a9107ef/b0544/GeoSure-Style%402x.webp 600w", "/static/96fbe88ba783f088fd2b87b21a9107ef/68fc1/GeoSure-Style%402x.webp 1200w", "/static/96fbe88ba783f088fd2b87b21a9107ef/a2303/GeoSure-Style%402x.webp 1800w", "/static/96fbe88ba783f088fd2b87b21a9107ef/4293a/GeoSure-Style%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/96fbe88ba783f088fd2b87b21a9107ef/aaafa/GeoSure-Style%402x.png 300w", "/static/96fbe88ba783f088fd2b87b21a9107ef/8db2a/GeoSure-Style%402x.png 600w", "/static/96fbe88ba783f088fd2b87b21a9107ef/6ca50/GeoSure-Style%402x.png 1200w", "/static/96fbe88ba783f088fd2b87b21a9107ef/4d07a/GeoSure-Style%402x.png 1800w", "/static/96fbe88ba783f088fd2b87b21a9107ef/ee4de/GeoSure-Style%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/96fbe88ba783f088fd2b87b21a9107ef/6ca50/GeoSure-Style%402x.png",
              "alt": "Before diving into UI design, I established an overall flavor for the design direction.",
              "title": "Before diving into UI design, I established an overall flavor for the design direction.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Before diving into UI design, I established an overall flavor for the design direction.`}</figcaption>{`
  `}</figure></p>
    <p>{`In the first round of redesign, I coordinated with the GeoSure team to translate their recent re-branding efforts into a component library that adheres to ADA guidelines while staying true to the visual direction of the brand. I designed the experience of the initial features, as well as the new features in round two, and was responsible for overall visual direction and execution of the app. For user onboarding flows and empty states, I created a set of illustrations and icons.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAADIElEQVQoz02T3W+TdRTH+y8YCLqxsrVU4stUxjZDvHFEbuadwo3ciNELY5QAGSFemAAx3hCSIUsHE+xeqmtR3IuRhDkCOLa10LHVtazrex+edmufvo6uWZn68TydF16cfH/nnO/5Pt/nJMfQ6IizyxHD5NQxTpNTjwSNgmYdpdYwHKvh7ut6PVHjmK+rMqNgckSp/0HPldqcoWVgnj3fP6LpqkSfh9YhL03DUUxDARp7XZj7HrLf7qXO6uLla3MYf4zQPpai8+ZjDk4omLt6eenQIUxHT2McDGBo/s7Fnj43z1+4jfmKm9Z+D/X2MA39fpp6pmiX/PhkiBcvz9JonaFuKESrCL5/y897P7vZfvgEnR8e5pUPPsXYv4ThBaub+guT7Dp9lYbeBxh73TWHO675ODK6yNkZhYtejVN3IrwzvMD2gSBt4yrWxRzdboUvbSM4fp9lYNrH3rEkBmPPNDt7Zniu+744mKVtcJ46cbjT5qfu2ym67kQ561I5MuZnW/cU2wbDfHR3BcdikqMTUS5Nevi4e4Azo/dpGU9h6HQucNDhZZ/tIe0Sbw4+wiwOLfYlOuwePrsV5IB9js8nlmmT/o4hEbyXxuZa5t2LI3xz4zbn7gX56m6I10eeYIirKywEowSiCovBCMlMjo6bKY7NZMjncrgfh/kzFGN+OcpK4SnNvyic8Wg8LRTwheMEwjHiaoqoVmLfqIqhoGUIR2NEYgkiggUhHhDBEyJYXE1KL04soZCIx1nVsuJC5dycxrNijmXphWVOVZOkimURFIdra2uktDwpcbaazVMqr/P2byrHptNUSkWS0kvnCqSll86Xag6/nstCtYyWy5OVKAtPW1unRRfk77/Y2KhSlXhWrfLP5iYdInhyNoMklCsblCQ2pafz9sqeulwa5715TrkyNbfHhWsLFNk/LoLhfAVftsJCpoJfMJCr8NavKp/8keZJaUPq6zxYXWcpt8VrvqHwhazDGSpxyVfA6i/Q48vjCBW3ftnyUwKLnJR+VjrquX5eJufW2/K/uuW/09O5utM3RhRekxW0yPtV+ZB+uv8CBa7TonAOU3gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c0d37f5fdb1b191d4db06787368aec7b/e93cc/Onboarding%402x.webp 300w", "/static/c0d37f5fdb1b191d4db06787368aec7b/b0544/Onboarding%402x.webp 600w", "/static/c0d37f5fdb1b191d4db06787368aec7b/68fc1/Onboarding%402x.webp 1200w", "/static/c0d37f5fdb1b191d4db06787368aec7b/a2303/Onboarding%402x.webp 1800w", "/static/c0d37f5fdb1b191d4db06787368aec7b/4293a/Onboarding%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/c0d37f5fdb1b191d4db06787368aec7b/aaafa/Onboarding%402x.png 300w", "/static/c0d37f5fdb1b191d4db06787368aec7b/8db2a/Onboarding%402x.png 600w", "/static/c0d37f5fdb1b191d4db06787368aec7b/6ca50/Onboarding%402x.png 1200w", "/static/c0d37f5fdb1b191d4db06787368aec7b/4d07a/Onboarding%402x.png 1800w", "/static/c0d37f5fdb1b191d4db06787368aec7b/ee4de/Onboarding%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/c0d37f5fdb1b191d4db06787368aec7b/6ca50/Onboarding%402x.png",
              "alt": "Onboarding is crucial for a product that presents otherwise esoteric information.",
              "title": "Onboarding is crucial for a product that presents otherwise esoteric information.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Onboarding is crucial for a product that presents otherwise esoteric information.`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB1ElEQVQoz3WSzW7TQBSF8wCIDSmtBAKWwGuVN4EH6J5VUdu0VSFlgWDfNagIClJC45mExrFd2+O/+H8Od8aisQO1dKyrmbnf3Hvu9KSUkIBW+6/Xmy2ERZ0+fzutNvYZdn9cyyRJMDEdMMtFmqZ4fT7H1oDjyRFDTyVC1loqu26DZd0B3tszMDRCqU6JICQFOmNoRLi/bxCQo6cSpn4M5kUo6eiHkQXTC1BWNS6vFlJEMZIKGtinpOOxkHVR4IKb+Ho5g4qPxgKbK6DEIkrBxVLf9m1m47Od6CqvbFdmeY64lCvgL0FGVJjZLvjiGio+bgN1yy0XO1+z2Wn5PYv1muv58PxAHztl8arlv0NRQOZGSIoKpiALHIF6bSh9GkrjIRBEIaI40pnDSfg/IGD8niPOCoznDr6MGPK6W+HGAcdg5MhlbMELHAjfok0X78Y+Ng9YC9jkIaMnoN6Kv8xhR0t10b/An44MHRszk7SwEfsuTqjCNQ9v7Fq3sOshtfyRB7SWA2XWiOJPXLW8VuEtugE+I+AWtfXizJY73wVenft4Sdq5ENg+s/BgwPBYAauyxG0qS3qZ9OBFWmRPT6bVw0OO/p4h7+xOcPdNIxWr6SvYo0OGP41+Dq2KF4ANAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/16248484a4b33dc5073f61cfc43536f4/e93cc/Icons%402x.webp 300w", "/static/16248484a4b33dc5073f61cfc43536f4/b0544/Icons%402x.webp 600w", "/static/16248484a4b33dc5073f61cfc43536f4/68fc1/Icons%402x.webp 1200w", "/static/16248484a4b33dc5073f61cfc43536f4/a2303/Icons%402x.webp 1800w", "/static/16248484a4b33dc5073f61cfc43536f4/4293a/Icons%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/16248484a4b33dc5073f61cfc43536f4/aaafa/Icons%402x.png 300w", "/static/16248484a4b33dc5073f61cfc43536f4/8db2a/Icons%402x.png 600w", "/static/16248484a4b33dc5073f61cfc43536f4/6ca50/Icons%402x.png 1200w", "/static/16248484a4b33dc5073f61cfc43536f4/4d07a/Icons%402x.png 1800w", "/static/16248484a4b33dc5073f61cfc43536f4/ee4de/Icons%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/16248484a4b33dc5073f61cfc43536f4/6ca50/Icons%402x.png",
              "alt": "Custom icons for broad safety categories.",
              "title": "Custom icons for broad safety categories.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Custom icons for broad safety categories.`}</figcaption>{`
  `}</figure></p>
    <Wrapper mdxType="Wrapper">
    <Container mdxType="Container">
        <AutoplayVideo src={dayNightVid} height={568} wrapperClassName="ta-center" className="dropshadow" mdxType="AutoplayVideo" />
   </Container>
    </Wrapper>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACOElEQVQoz22SS08TURiG+QeCUHthpfY6M6XX6QVCEYUosIDEy8KlOzeuXLty6U/AjXHhwvAL3JmYEIwLITEKw1w6xbZTeiHIBtrHM9Ug2J7knXPyTfKc97zfN9Lt9XDXptVgy6hz3GnRbLZodTo0hdx1dHREKBTm2tgoN6YXePbyFe/evmH99TrvNza4//ARY6NXmAwEGOn9BdrOAZq9h1W2qB2U2dQqfLEP+/86AhgOhfCMjxNPpbm7vMLy0j2WFu+w+uQpuZUHeK+OEbgI1FvbbFkfsUwT27YxDJNavX7usA+cmECRJXJqhmQiQUoonU4jiZrf6yXg9/8Dao0dvlY+UanYlO0K+4ZBo+EMAGVFRlWzApoln89RKBZRpBg+n+8ysNrWqdgm2/oPdsxdTMOiPsTh1FScYi6HOj9DYaHETHGaRGIK3/8OD5sNylaZ77u77GkaliWyrNUGgHFFEe5USnOzzN++RUG4dGsDQMdpiNwMyhUDu6qLs0V1CFCR5f5z1WyGafHcfCGPLGoDQDcvTTjb10WnbZ0Dy+TQqQ1tigtzlc2kySQUEvKQDB3HQdd1tD0N07T48M3ks1G9NDYTYmzSqSSl0iyZdIq1tVUeP3+BNLuI3zOO/yLw5NdJ30lHDLO7G402P9vH5w4j4TBej4dQMIgUi4kLgqSSSdS5Ba7HFCZ93j+DfXZ6iqtu94xet3su8QFRQ1zYabcpiM5K0agYEam/ywIai0SIBm+iRCPERY6ufgPxdkt6+b9dxwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/890fc00c23c75220c10af82a00ebf3a7/e93cc/Nightmode-01%402x.webp 300w", "/static/890fc00c23c75220c10af82a00ebf3a7/b0544/Nightmode-01%402x.webp 600w", "/static/890fc00c23c75220c10af82a00ebf3a7/68fc1/Nightmode-01%402x.webp 1200w", "/static/890fc00c23c75220c10af82a00ebf3a7/a2303/Nightmode-01%402x.webp 1800w", "/static/890fc00c23c75220c10af82a00ebf3a7/4293a/Nightmode-01%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/890fc00c23c75220c10af82a00ebf3a7/aaafa/Nightmode-01%402x.png 300w", "/static/890fc00c23c75220c10af82a00ebf3a7/8db2a/Nightmode-01%402x.png 600w", "/static/890fc00c23c75220c10af82a00ebf3a7/6ca50/Nightmode-01%402x.png 1200w", "/static/890fc00c23c75220c10af82a00ebf3a7/4d07a/Nightmode-01%402x.png 1800w", "/static/890fc00c23c75220c10af82a00ebf3a7/ee4de/Nightmode-01%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/890fc00c23c75220c10af82a00ebf3a7/6ca50/Nightmode-01%402x.png",
              "alt": "The night mode feature allows users to avoid drawing attention to themselves.",
              "title": "The night mode feature allows users to avoid drawing attention to themselves.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The night mode feature allows users to avoid drawing attention to themselves.`}</figcaption>{`
  `}</figure></p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAC7ElEQVQoz3WTS28bVRiG/UtI3UQVQiFRpYpFF/SPsEQIxKIgQFUr0arcxIKK0kqogAQLUO0kTVx6pSqoqCmpcGJXjRMSXzL38W3GHs/dM07zcJyEJYtXM+ec7zzf9WSmcg2yeZlsTmJqXuFYfocJsT4ypzCZl5gUe5Pif2pe5cj1Bi/llX37/zvL5EsSRcXmmdGnMRiyuGnyWkHhjd8NKmaPVc3mSc3gn27Ao6rBy/Myb/9pUG07/K1a/FU32bIC7m8ZvDIvkal0Y5qWixeN2AN2Inj9bpP3n3Zhb4RlWZhmE9/zSEe7TC+qfFruibMUs9Wh1WwRuC6D4S7Hl1Qyt27e5uq1H/g5t8DCYoH7d+4y+8sGH632CH0fqePgBDHLNZPOIGBmSeOTkg1JQF84CeOI4TCi7QYcL2hkzp9+i7PvvcOlzy/w3eUvuPLlxxy7tsKZ0oDYczFshzgZsWO0sLxwH/hZ2YLIxh3YpIkPqYAH/gHwpytfcfXyJe7dzPHg1xxPHhY4cX2DD4t9ktCj2RPAdBeta+OEMTOLGhdFyntpJBx4eMOYeJTQFlnsA4srKxTX1qjVq1RrNRqNBieXJAHs4XQ71BSDSEQoKTr2wBcR6lwoWcTRANW26Ia+qF+A4R1GqMoSsm7i+wHDMCCNIk7d0UVTLEZRQHcg6iSA7Z5w4IfMFgzOLSvElkm3pdMX6rVE1zWT2RsymepWlbXyczEWPk/bMdtOIoAGp1c6oucvaIpGVCSDIEnFJCS8WtA591imrat8u6rwTVHjt02VuqwyMwbKyjbSzib36gZfr+ncWDc5dVvng/HYCKDjeiJdjd00JUkSZgTw/LKM29T48ZnG92WdR9saqnoI3Oz0KTf71Hs+uuPTaPc5eUvnzcdtNDdmveOzbvbZsiM2rJBpUcN3/5Cp1GVKNYXndYWy0IOKxPSCAI6fzVhH51Sy4psVT2lCKDt3sD+1MJbGf3YTcwc2k/s2Bzp6uB7f+xegVhnoJlGecQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4c3a32784e17ad3a21ee1b579dc77f50/e93cc/Location%402x.webp 300w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/b0544/Location%402x.webp 600w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/68fc1/Location%402x.webp 1200w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/a2303/Location%402x.webp 1800w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/4293a/Location%402x.webp 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/4c3a32784e17ad3a21ee1b579dc77f50/aaafa/Location%402x.png 300w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/8db2a/Location%402x.png 600w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/6ca50/Location%402x.png 1200w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/4d07a/Location%402x.png 1800w", "/static/4c3a32784e17ad3a21ee1b579dc77f50/ee4de/Location%402x.png 2400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/png"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4c3a32784e17ad3a21ee1b579dc77f50/6ca50/Location%402x.png",
              "alt": "The Location Detail view puts the overall safety score front and center followed by supporting and related information.",
              "title": "The Location Detail view puts the overall safety score front and center followed by supporting and related information.",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`The Location Detail view puts the overall safety score front and center followed by supporting and related information.`}</figcaption>{`
  `}</figure></p>
    <CaseStudyConclusion headline="Results" text="Since launch, GeoSure has been featured in the Apple App Store, was included in Newsweek's list of the best travel apps of 2019, and has appeared on several “best of” lists for travel apps." mdxType="CaseStudyConclusion" />
    <NextCaseStudy slug="dig-colors" image={{
      src: digColorsThumb,
      width: 736,
      height: 460
    }} title="DIG Colors" description="A flexible color system to scale the Dropbox brand" tags='Design Strategy, Visual Design' mdxType="NextCaseStudy" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      